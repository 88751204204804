import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  Layout,
  Hero,
  About,
  Experiences,
  Featured,
  Projects,
  Contact,
} from "@components";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const StyledMainContainer = styled.main`
  counter-reset: section;
`;

const Container = styled.div`
  position: absolute;
  height: 100vh;
  max-width: 70vw;
  right: 0;
  z-index: -2;
  cursor: none;
  user-select: none;
  opacity: 0.8;
  padding-top: 100px;
  display: grid;

  & > div:first-of-type {
    grid-area: 1/1;
    width: 70vw;
    height: 100vh;
    z-index: 1;
    background: radial-gradient(
      circle at 51% 52%,
      rgba(0, 0, 0, 0) 51%,
      rgba(19, 19, 19, 1) 72%,
      rgba(19, 19, 19, 1) 93%
    );
  }

  .hero-img {
    grid-area: 1/1;
    aspect-ratio: 3/1;
    z-index: -1;
    width: 100%;
    height: calc(100% - 100px);
    object-fit: cover;
    opacity: 0.5;
  }

  @media (max-width: 600px) {
    display: none;
  }

  @media (max-width: 768px) {
    top: 0;
    padding-top: 50px;
  }

  @media (max-width: 1200px) {
    top: 20px;
  }
`;

const IndexPage = ({ location }) => {
  const result = useStaticQuery(
    graphql`
      query {
        allContentfulLandingPage {
          edges {
            node {
              headPhoto {
                gatsbyImageData
              }
            }
          }
        }
      }
    `
  );

  const data = result.allContentfulLandingPage.edges[0].node;

  return (
    <Layout location={location}>
      <StyledMainContainer className="fillHeight">
        {data.headPhoto && (
          <Container>
            <div />
            <GatsbyImage
              image={data.headPhoto.gatsbyImageData}
              alt="dedaldino-hero"
              className="hero-img"
            />
          </Container>
        )}
        <Hero />
        <About />
        <Experiences />
        <Featured />
        <Projects />
        <Contact />
      </StyledMainContainer>
    </Layout>
  );
};

IndexPage.propTypes = {
  location: PropTypes.object.isRequired,
};

export default IndexPage;
